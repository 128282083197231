<template>
  <div class="mod-user">
    <div class="searchBox">
      <el-form :inline="true" :model="dataForm" @keyup.enter.native="getDataList()" size="small">
        <el-form-item label="车牌号">
          <el-input v-model="dataForm.vehiclePlate" placeholder="支持模糊查询" clearable></el-input>
        </el-form-item>
        <el-form-item label="司机姓名">
          <el-input v-model="dataForm.driverName" placeholder="支持模糊查询" clearable></el-input>
        </el-form-item>
        <el-form-item label="司机号码">
          <el-input v-model="dataForm.driverPhone" placeholder="支持模糊查询" clearable></el-input>
        </el-form-item>
        <el-form-item label="加油日期">
          <el-date-picker
            v-model="dataForm.refuelDate"
            type="daterange" align="right" unlink-panels range-separator="-"
            size="small" value-format="yyyy-MM-dd"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            @change="getDataList"
            :picker-options="$store.state.common.pickerOptions">
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button @click="getDataList()" type="primary">查询</el-button>
          <el-button @click="downLoad()" type="success">导出</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="tableBox">
      <el-table @row-click="rowClick"
                :summary-method="getSummaries"
                show-summary
                :row-style="selectedHighlight"
                :data="dataList"
                :height="tableHeight"
                border size="small"
                v-loading="dataListLoading"
                @selection-change="selectionChangeHandle"
                style="width: 100%;">
        <el-table-column type="index" label="序号" width="40" align="center" fixed="left"></el-table-column>
        <el-table-column prop="orderNo" label="订单号" width="110" align="center">
        </el-table-column>
        <el-table-column prop="simpleName" label="客户名" width="110" align="center">
        </el-table-column>
        <el-table-column prop="takeRoute" label="提货点" min-width="90" :show-overflow-tooltip="true" align="center">
        </el-table-column>
        <el-table-column prop="giveRoute" label="卸货点" min-width="80" :show-overflow-tooltip="true" align="center">
        </el-table-column>
        <el-table-column prop="vehiclePlate" label="车牌号" width="80" align="center">
        </el-table-column>
        <el-table-column prop="vehicleType" label="车型" width="80" align="center">
        </el-table-column>
        <el-table-column prop="addTime" label="订单时间" width="120" align="center">
        </el-table-column>
        <el-table-column prop="refuelVolume" label="加油量(升)" width="80" align="center">
        </el-table-column>
        <el-table-column label="加油凭证" width="60" align="center">
          <template slot-scope="scope">
            <span @click="viewPhoto(scope.row, refuelVolumeTypeList)" style="color: blue;cursor:pointer">查看</span>
          </template>
        </el-table-column>
        <el-table-column prop="refuelAmount" label="加油金额" width="80" align="center">
        </el-table-column>
        <el-table-column label="金额凭证" width="60" align="center">
          <template slot-scope="scope">
            <span @click="viewPhoto(scope.row, refuelAmountTypeList)" style="color: blue;cursor:pointer">查看</span>
          </template>
        </el-table-column>
        <el-table-column prop="refuelCashVolume" label="现金加油量(升)" width="80" align="center">
        </el-table-column>
        <el-table-column label="加油凭证" width="60" align="center">
          <template slot-scope="scope">
            <span @click="viewPhoto(scope.row, refuelCashVolumeTypeList)" style="color: blue;cursor:pointer">查看</span>
          </template>
        </el-table-column>
        <el-table-column prop="refuelCashAmount" label="现金加油金额" width="80" align="center">
        </el-table-column>
        <el-table-column label="金额凭证" width="60" align="center">
          <template slot-scope="scope">
            <span @click="viewPhoto(scope.row, refuelCashAmountTypeList)" style="color: blue;cursor:pointer">查看</span>
          </template>
        </el-table-column>
        <el-table-column prop="currentDistance" label="车辆实际里程" width="80" align="center">
        </el-table-column>
        <el-table-column label="里程凭证" width="60" align="center">
          <template slot-scope="scope">
            <span @click="viewPhoto(scope.row, currentDistanceTypeList)" style="color: blue;cursor:pointer">查看</span>
          </template>
        </el-table-column>
        <el-table-column prop="createTime" label="登记日期" width="130" align="center">
        </el-table-column>
        <el-table-column prop="driverName" label="司机名称" width="80" align="center">
        </el-table-column>
        <el-table-column prop="driverPhone" label="司机电话" width="90" align="center">
        </el-table-column>
        <el-table-column prop="remark" label="备注" min-width="120" :show-overflow-tooltip="true" header-align="center"
                         align="left">
        </el-table-column>
        <el-table-column width="120" label="操作" fixed="right" align="center">
          <template slot-scope="scope">
            <el-button type="text" @click="addOrUpdateHandle('view',scope.row.id)" size="small">详情</el-button>
            <el-button type="text" @click="addOrUpdateHandle('update',scope.row.id)" size="small">修改</el-button>
            <el-button type="text" @click="deleteInfo(scope.row.id)" size="small">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="sizeChangeHandle"
        @current-change="currentChangeHandle"
        :current-page="pageIndex"
        :page-sizes="[20, 50, 100]"
        :page-size="pageSize"
        :total="totalPage"
        layout="total, sizes, prev, pager, next, jumper">
      </el-pagination>
    </div>
    <el-dialog title="加油详情"
               :visible.sync="addOrUpdateVisible"
               custom-class="popup-detail"
               width="700">
      <el-form :model="detailForm" ref="detailForm" size="small" label-width="140px">
        <el-row :gutter="50">
          <el-col :span="12">
            <el-form-item label="司机姓名" prop="driverName">
              <el-input v-model="detailForm.driverName" :disabled="true"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="司机电话" prop="driverPhone">
              <el-input v-model="detailForm.driverPhone" :disabled="true"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="50">
          <el-col :span="12">
            <el-form-item label="订单号" prop="orderNo">
              <el-input v-model="detailForm.orderNo" :disabled="true"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="车牌号" prop="vehiclePlate">
              <el-input v-model="detailForm.vehiclePlate" :disabled="true"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="50">
          <el-col :span="12">
            <el-form-item label="车型" prop="vehicleType">
              <el-input v-model="detailForm.vehicleType" :disabled="true"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="加油时间" prop="addTime">
              <el-date-picker
                v-model="detailForm.addTime"
                type="datetime"
                :disabled="isView"
                default-time="12:00:00"
                format="yyyy-MM-dd HH:mm"
                value-format="yyyy-MM-dd HH:mm"
                style="width: 100%"
                placeholder="选择日期时间">
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="50">
          <el-col :span="12">
            <el-form-item label="加油量(升)" prop="refuelVolume">
              <el-input v-model="detailForm.refuelVolume" placeholder="加油量(升)" :disabled="isView"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="加油金额(元)" prop="refuelAmount">
              <el-input v-model="detailForm.refuelAmount" placeholder="加油金额(元)" :disabled="isView"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="50">
          <el-col :span="12">
            <el-form-item label="现金加油量(升)" prop="refuelCashVolume">
              <el-input v-model="detailForm.refuelCashVolume" placeholder="现金加油量(升)" :disabled="isView"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="现金加油金额(元)" prop="refuelCashAmount">
              <el-input v-model="detailForm.refuelCashAmount" placeholder="现金加油金额(元)" :disabled="isView"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="50">
          <el-col :span="12">
            <el-form-item label="当前里程" prop="currentDistance">
              <el-input v-model="detailForm.currentDistance" placeholder="当前里程" :disabled="isView"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="50">
          <el-col :span="24">
            <el-form-item label="备注" prop="remark">
              <el-input type="textarea" :rows="2" v-model="detailForm.remark" placeholder="备注"
                        :disabled="isView"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="50">
          <el-col :span="20">
            <el-form-item label="加油凭证">
              <al-upload :fileData="refuelVolumeFileList"
                         :fileUrl="uploadUrl"
                         :isView="isView"
                         :format="'img'"
                         :type="'refuelVolumeType'"
                         :limit="2"
                         :businessType="7058"
                         @getFileData="getFileData"></al-upload>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addOrUpdateVisible = false">取 消</el-button>
        <el-button type="primary" @click="updateInfo">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 查看图片 -->
    <view-image ref="viewPhoto"></view-image>
  </div>
</template>

<script>
import viewImage from '@/views/commonPop/viewImage-fy'

export default {
  data () {
    return {
      dataForm: {
        vehiclePlate: null,
        refuelDate: null,
        driverPhone: null,
        driverName: null
      },
      detailForm: {
        id: null,
        orderNo: null,
        vehicleType: null,
        driverName: null,
        driverPhone: null,
        vehiclePlate: null,
        addTime: null,
        remark: null,
        refuelVolume: null,
        refuelAmount: null,
        refuelCashVolume: null,
        refuelCashAmount: null,
        currentDistance: null,
        enclosureInfoList: [],
        passingCardFee: null,
        costInfoId: null
      },
      refuelVolumeFileList: [],
      uploadUrl: '',
      accessToken: '',
      tableHeight: '70vh',
      dataList: [],
      pageIndex: 1,
      pageSize: 20,
      getIndex: -1,
      totalPage: 0,
      /**
       * 加油金额
       */
      refuelAmountTypeList: [7006],
      /**
       * 加油升
       */
      refuelVolumeTypeList: [7059],
      /**
       * 现金加油金额
       */
      refuelCashAmountTypeList: [7001],
      /**
       * 现金加油升数
       */
      refuelCashVolumeTypeList: [7088],
      /**
       * 当前里程
       */
      currentDistanceTypeList: [7058],
      dataListLoading: false,
      dataListSelections: [],
      addOrUpdateVisible: false,
      isView: false,
      itemRow: {}
    }
  },
  components: {
    viewImage
  },
  created () {
    this.setDefaultDate()
  },
  activated () {
    let params = this.$route.query
    if (params.sumTable) {
      this.dataForm.vehiclePlate = params.vehiclePlate
      this.dataForm.driverPhone = params.driverPhone
      this.dataForm.driverName = params.driverName
      this.dataForm.refuelDate = params.refuelDate
    }
    this.getDataList()
    this.init()
  },
  computed: {
    dictTypeMap: {
      get () {
        return this.$store.state.common.dictTypeMap
      }
    }
  },
  methods: {
    setDefaultDate () {
      let end = new Date()
      let start = new Date()
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
      this.dataForm.refuelDate = [this.dateFormatter(start, 'yyyy-MM-dd'), this.dateFormatter(end, 'yyyy-MM-dd')]
    },
    init () {
      this.uploadUrl = this.$http.adornUrl('/upload/file')
      this.accessToken = this.$cookie.get('TmsToken')
    },
    initFileList (data) {
      let infoList = data.enclosureInfoList
      if (infoList) {
        this.detailForm.enclosureInfoList = data.enclosureInfoList
        // 不直接赋值的原因是引用的是一个数组，删除会有问题(表面删除了实际数据没有)
        for (let i = 0; i < infoList.length; i++) {
          this.refuelVolumeFileList.push({
            id: infoList[i].id,
            name: infoList[i].name,
            url: infoList[i].url,
            businessType: infoList[i].businessType,
            status: infoList[i].status,
            uid: infoList[i].uid
          })
        }
      }
    },
    // 文件上传成功保存id和类型
    saveFileList (response, file) {
      if (response.code !== 100000) {
        this.$message.error(response.message)
      }
      let resData = response.data
      file.id = resData.id
      this.detailForm.enclosureInfoList.push({ id: resData.id, businessType: resData.businessType })
    },
    getFileData (data) {
      let fileList = data.fileList
      this.detailForm.enclosureInfoList = []
      for (let i = 0; i < fileList.length; i++) {
        this.detailForm.enclosureInfoList.push(fileList[i])
      }
    },
    getDetail (id) {
      this.refuelVolumeFileList = []
      this.$http({
        url: this.$http.adornUrl('/refuelRegister/' + id),
        method: 'get',
        params: this.$http.adornParams()
      }).then(({ data }) => {
        this.detailForm.id = id
        this.detailForm.driverName = data.driverName
        this.detailForm.driverPhone = data.driverPhone
        this.detailForm.vehiclePlate = data.vehiclePlate
        this.detailForm.addTime = data.addTime
        this.detailForm.currentDistance = data.currentDistance
        this.detailForm.refuelVolume = data.refuelVolume
        this.detailForm.refuelAmount = data.refuelAmount
        this.detailForm.refuelCashVolume = data.refuelCashVolume
        this.detailForm.refuelCashAmount = data.refuelCashAmount
        this.detailForm.orderNo = data.orderNo
        this.detailForm.remark = data.remark
        this.detailForm.vehicleType = data.vehicleType
        this.detailForm.passingCardFee = data.passingCardFee
        this.detailForm.costInfoId = data.costInfoId
        this.initFileList(data)
      })
    },
    /**
     * 修改信息
     */
    updateInfo () {
      this.$http({
        url: this.$http.adornUrl(`/refuelRegister/update`),
        method: 'POST',
        data: this.$http.adornData(this.detailForm)
      }).then(({ data }) => {
        if (data) {
          this.$message({
            message: '操作成功',
            type: 'success',
            duration: 1000,
            onClose: () => {
              this.addOrUpdateVisible = false
              this.getDataList()
            }
          })
        } else {
          this.$message.error('操作失败')
        }
      })
    },
    /**
     *自定义合计
     */
    getSummaries (param) {
      const { columns, data } = param
      const sums = []

      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '合计'
          return
        }
        const values = data.map(item => {
          // 加油量，加油金额
          if (column.property && (column.property.indexOf('refuelVolume') > -1 || column.property.indexOf('refuelAmount') > -1)) {
            return this.getValue(item[column.property])
          }
          if (column.property && (column.property.indexOf('refuelCashVolume') > -1 || column.property.indexOf('refuelCashAmount') > -1)) {
            return this.getValue(item[column.property])
          }
        })
        if (!values.every(value => isNaN(value))) {
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr)
            if (!isNaN(value)) {
              return this.numFilter(this.getValue(Number(prev) + curr))
            } else {
              return this.numFilter(this.getValue(prev))
            }
          }, 0)
        } else {
          sums[index] = ''
        }
      })
      return sums
    },
    getValue (value) {
      if (value === 0) {
        return ''
      } else {
        return value
      }
    },
    getAllSummaries (row) {
      // 合计参数，按顺序返回
      let param = ['合计', '', '', '', '', '', '', '',
        row.refuelVolume, '',
        row.refuelAmount, '',
        row.refuelCashVolume, '',
        row.refuelCashAmount,
        '', '', '', '', '', '', '', '']
      // 创建列表行
      let tr = document.createElement('tr')
      // 删除上一条合计
      if (document.querySelector('.el-table__footer-wrapper table tbody').childElementCount === 2) {
        document.querySelector('.el-table__footer-wrapper table tbody').lastChild.remove()
      }
      // td赋值
      param.forEach((item, index) => {
        let td = document.createElement('td')
        td.innerHTML = item
        td.align = 'center'
        tr.appendChild(td)
      })
      // 添加合计
      document.querySelector('.el-table__footer-wrapper table tbody').appendChild(tr)
    },
    // 查看照片
    viewPhoto (row, typeList) {
      this.$refs.viewPhoto.init(row.id, typeList)
    },
    /**
     * 点击表格变色start
     */
    selectedHighlight ({ row, rowIndex }) {
      if ((this.getIndex) === rowIndex) {
        return {
          'background-color': 'rgb(250, 195, 100)'
        }
      }
    },
    rowClick (row) {
      this.getIndex = row.index
    },
    downLoad () {
      let refuelDate = this.dataForm.refuelDate
      let timeParam = {}
      if (refuelDate && refuelDate.length === 2) {
        timeParam.refuelBeginDate = refuelDate[0]
        timeParam.refuelEndDate = refuelDate[1]
      }
      this.$http({
        url: this.$http.adornUrl(`/export/refuelRegisterExport`),
        method: 'get',
        responseType: 'blob',
        params: this.$http.adornParams({
          'driverName': this.dataForm.driverName,
          'vehiclePlate': this.dataForm.vehiclePlate,
          'driverPhone': this.dataForm.driverPhone,
          'refuelBeginDate': timeParam.refuelBeginDate,
          'refuelEndDate': timeParam.refuelEndDate
        })
      }).then(({ data }) => {
        const blob = data
        const reader = new FileReader()
        reader.readAsDataURL(blob)
        reader.onload = (e) => {
          const a = document.createElement('a')
          a.download = '加油登记表.xls'
          a.href = e.target.result
          document.body.appendChild(a)
          a.click()
          document.body.removeChild(a)
        }
      })
    },
    // 获取数据列表
    getDataList () {
      this.dataListLoading = true
      let refuelDate = this.dataForm.refuelDate
      let timeParam = {}
      if (refuelDate && refuelDate.length === 2) {
        timeParam.refuelBeginDate = refuelDate[0]
        timeParam.refuelEndDate = refuelDate[1]
      }
      this.tableHeight = '70vh'
      this.$http({
        url: this.$http.adornUrl('/refuelRegister/findList'),
        method: 'get',
        params: this.$http.adornParams({
          'page': this.pageIndex,
          'limit': this.pageSize,
          'driverName': this.dataForm.driverName,
          'vehiclePlate': this.dataForm.vehiclePlate,
          'driverPhone': this.dataForm.driverPhone,
          'refuelBeginDate': timeParam.refuelBeginDate,
          'refuelEndDate': timeParam.refuelEndDate
        })
      }).then(({ data }) => {
        if (data) {
          this.dataList = data.list
          this.totalPage = data.total
          this.getAllSummaries(data.totalRow)
        } else {
          this.dataList = []
          this.totalPage = 0
        }
        this.tableHeight = '75vh'
        this.dataListLoading = false
      })
    },
    // 每页数
    sizeChangeHandle (val) {
      this.pageSize = val
      this.pageIndex = 1
      this.getDataList()
    },
    // 当前页
    currentChangeHandle (val) {
      this.pageIndex = val
      this.getDataList()
    },
    // 多选
    selectionChangeHandle (val) {
      this.dataListSelections = val
    },
    addOrUpdateHandle (todo, id) {
      this.getDetail(id)
      this.addOrUpdateVisible = true
      this.isView = todo === 'view'
    },
    deleteInfo (id) {
      this.$confirm('删除后无法恢复, 确定删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$http({
          url: this.$http.adornUrl(`/refuelRegister/delete/` + id),
          method: 'delete'
        }).then(({ data }) => {
          if (data) {
            this.$message({
              message: '操作成功',
              type: 'success',
              duration: 1000,
              onClose: () => {
                this.getDataList()
              }
            })
          } else {
            this.$message.error('操作失败')
          }
        })
      }).catch(() => {
      })
    }
  }
}
</script>
